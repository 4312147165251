<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-black cl-main ftw500">抢购订单详情</div>

			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">

					<div class="">
						<div class="">
							
							<div class="">
								<div class="flex alcenter">
									<div class="flex alcenter">
										<div class="ft14 ftw400 cl-info">订单编号：</div>
										<div class="ft14 ftw600 cl-main">{{order.member_snap_up_id}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info">下单时间：</div>
										<div class="ft14 ftw600 cl-main">{{order.add_time_format}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info text-over4">有效期至：</div>
										<div class="ft14 ftw600 cl-theme text-over4">{{order.valid_end_time}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="project-snap-up-order-detail-info">
							<div class="flex ">
								<div class="pl40" style="width: 33%;border-right: 1px solid #EBEDF5;">
									<div class="ft14 ftw500 cl-main">订单状态</div>
									<div class="ft20 ftw600 cl-theme mt16">{{order.status_means}}</div>
									
								</div>
								<div class="" style="width: 33%;">
									<div class="flex" style="border-right: 1px solid #EBEDF5; ">
										<div class="ml40 text-over4">
											<div class="ft14 ftw500 cl-main text-over4">付款信息</div>
											<div class="mt16">
												<div class="flex">
													<div>
														<div class="flex alcenter ">
															<div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
															<div class="ft14 ftw600 cl-main text-over4" >
																{{order.pay_type_mean}}
															</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">支付时间：</div>
															<div class="ft14 ftw600 cl-main text-over4" >
																{{order.pay_time_format}}
															</div>
														</div>
														
														<div class="flex  mt10">
															<div class="ft14 ftw400 cl-info text-over4">实付金额：</div>
															<div class="ft14 ftw600 cl-main text-over4">
																¥{{order.need_pay}}</div>
															<div class="ml5 ft12 ftw400" style="color: #A6AEC2;">
																<div class="flex alcenter">
																	<div>原价：</div>
																	<div class="text-line">
																		¥208.00
																	</div>
																</div>
															</div>	
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="ml40">
										<div class="ft14 ftw500 cl-main">客户信息</div>
										<div class="mt16">
											<div class="flex">
												<div>
													<div class="flex alcenter">
														<div class="ft14 ftw400 cl-info text-over4">客户姓名：</div>
														<div class="ft14 ftw600 cl-main text-over4">
															{{order.member_name}}</div>
													</div>
													<div class="flex alcenter mt10">
														<div class="ft14 ftw400 cl-info text-over4">手机号码：</div>
														<div class="ft14 ftw600 cl-main text-over4">
															{{order.member_mobile}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div class="mt30">
						<div class="project-snap-up-order-detail-project">
							<div class="flex space alcenter">
								<div class="flex alcenter">
									<img :src="order.cover_img" >
									<div class="ml10">
										<div class="ft14 ftw600 cl-black">{{order.abbreviation}}</div>
										<div class="mt8">
											<div class="flex alcenter">
												<div class="flex alcenter">
													<div class="ft12 ftw400 cl-info">购买价：</div>
													<div class="ft14 ftwblod cl-price">¥{{order.price}}</div>
												</div>
												<div class="flex alcenter ml8">
													<div class="ft12 ftw400 cl-info">门市价：</div>
													<div class="ft12 cl-notice text-line">¥{{order.market_price}}</div>
												</div>
											</div>
										</div>
										<div class="mt8">
											<div class="flex alcenter">
												<div class="flex alcenter">
													<div class="ft12 ftw400 cl-info">总次数：</div>
													<div class="ft14 ftw600 cl-black">{{order.total_num}}次</div>
												</div>
												<div class="flex alcenter ml40">
													<div class="ft12 ftw400 cl-info">剩余次数：</div>
													<div class="ft14 ftw600 cl-theme ">{{order.remain_num}}次</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								
								<div>
									
									<div class="button-write-off " v-if="order.remain_num>0 && order.status_another==1" @click="writeOffAct()">核销</div>
									<div class="button-write-off-over " v-if="order.remain_num<=0 && order.status_another==1" >核销完</div>
									<div class="button-write-off-over " v-if="order.status_another==8" >已完成</div>
									<div class="button-write-off-over  " v-if="order.status_another==-1">已过期</div>
								</div>
							</div>
						</div>
					</div>
				
					<div class="flex end mt10">
						<div class="flex">
							<div class="ft12 ftw400 cl-info">
								<div class="flex end">合计</div>
								<div class="flex end mt8" v-if="order.coupon_money>0">优惠券抵扣</div>
								<div class="flex end mt8" v-if="order.integral_balance>0">积分抵扣</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">订单金额</div>
							</div>
					
							<div class="ml60 ft12 ftw400 cl-info">
								<div class="flex end">¥{{order.total_price}}</div>
								<div class="flex end mt8" v-if="order.coupon_money>0">¥{{order.coupon_money}}</div>
								<div class="flex end mt8" v-if="order.integral_balance>0">¥{{order.integral_balance}}
								</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">¥{{order.need_pay}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<div v-if="writeOffVisible">
				<write-off :datas="order" :visible="writeOffVisible" @cancel="cancelWriteOff" @ok="okWriteOff"></write-off>
			</div>
		</a-spin>
	</div>

</template>

<script>
	import writeOff from './components/OrderSnapupDetail/modal/writeOff.vue';
	export default {
		components:{
			writeOff,
		},
		data() {
			return {
				loading: false,
				writeOffVisible:false,
				member_snap_up_id: 0,
				order: {
					member_snap_up_id: 0,
					add_time_format: '',
					valid_end_time_format: '',
					status: 1,
					status_means: '',
					need_pay: 0,
					pay_type_mean: '',
					pay_time_format: '',
					total_price: 0,
					coupon_money: 0,
					integral_balance: 0,
					member_name: '',
					member_mobile: '',
					cover_img:null,
					abbreviation:'',
					market_price:0,
					price:0,
					total_num:0,
					remain_num:0,
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.member_snap_up_id = 0;
			} else {
				this.member_snap_up_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods: {
			loaddata() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getSnapupOrderDetail', {
					member_snap_up_id: this.member_snap_up_id
				}).then(res => {
					this.order = res.detail;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
					this.$router.go(-1);
				})
			},
			
			writeOffAct(){
				this.writeOffVisible=true;
			},
			cancelWriteOff(){
				this.writeOffVisible=false;
			},
			okWriteOff(){
				this.writeOffVisible=false;
				this.loaddata();
			},
			
		}
	}
</script>

<style>
	.project-snap-up-order-detail-tag {
		padding: 1px 8px;
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}

	.project-snap-up-order-detail.tag {
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}


	.project-snap-up-order-detail-info {
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		padding: 24px 0px;
	}

	.project-snap-up-order-detail-subcard-item {
		width: 100%;
		height: 100px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		margin-bottom: 20px;
		padding: 20px;
	}

	.project-snap-up-order-detail-subcard-item img {
		width: 75px;
		height: 60px;
	}

	.button-deliver {
		width: 96px;
		height: 40px;
		background: #4772FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}
	
	.project-snap-up-order-detail-project{
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		padding: 20px;
	}
	
	.project-snap-up-order-detail-project img{
		width: 100px;
		height: 80px;
	}
</style>
